import "./App.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import UserDashboard from "./Pages/UserDashboard";
import AdminDashboard from "./Pages/AdminDashboard";
import LoginPage from "./Pages/Login";
import Register from "./Pages/Register";
import HeaderBar from "./Components/HeaderBar";
import { useEffect, useState } from "react";

function App() {
  const [isLogged, setIsLogged] = useState("");
  const [userType, setUserType] = useState("");

  useEffect(() => {
    setIsLogged(localStorage.getItem("log"));
    setUserType(localStorage.getItem("type"));
  }, [isLogged, userType]);

  if (isLogged === "true" && userType === "customer") {
    return (
      <div>
        <HeaderBar />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<UserDashboard />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }

  if (isLogged === "true" && userType === "admin") {
    return (
      <div>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<AdminDashboard />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/register" element={<Register />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
