import React from "react";
import style from "./headerbarstyle.module.css";
import { Button } from "@mui/material";
import { materialButtonStyle } from "../../styles";

export default function HeaderBar({ isAdmin = false }) {
  const logOut = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div className={style.headerBar}>
      <div className={style.avatarRow}>
        <div className={style.nameColumn}>
          <h3>
            <strong>Welcome</strong>
          </h3>
          <h3 className={style.name}>
            <em>{isAdmin ? `Admin` : `Customer`}</em>
          </h3>
        </div>
      </div>
      <div>
        <Button variant="contained" sx={materialButtonStyle} onClick={logOut}>
          Log out
        </Button>
      </div>
    </div>
  );
}
