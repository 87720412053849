import React, { useState } from "react";
import { formStyle, textFieldStyle } from "../styles";
import { Box, TextField, Autocomplete, Button } from "@mui/material";
import axios from "axios";
import { REGISTER_URL } from "../constansts";
import Swal from "sweetalert2";

export default function Register() {
  const [pass, setPass] = useState("");
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");

  const registerAccount = async (e) => {
    e.preventDefault();
    const data = {
      userName,
      userEmail: email,
      userPassword: pass,
      userType: "customer",
    };
    const res = await axios.post(REGISTER_URL, data);
    console.log(res.data);

    if (res.data === "UserAdded") {
      Swal.fire("Registration Done successfully");
    } else {
      Swal.fire("Registration Failed");
    }
  };

  return (
    <div>
      <Box component="form" sx={formStyle}>
        <h2>Create New Account</h2>
        <TextField
          id="user-name"
          onChange={(e) => setUserName(e.target.value)}
          sx={textFieldStyle}
          label="User Name"
          variant="outlined"
        />
        <TextField
          id="user-pass"
          onChange={(e) => setEmail(e.target.value)}
          sx={textFieldStyle}
          label="User Email"
          variant="outlined"
        />
        <TextField
          id="user-pass"
          onChange={(e) => setPass(e.target.value)}
          sx={textFieldStyle}
          label="Password"
          type={"password"}
          variant="outlined"
        />
        <Button
          variant="contained"
          onSubmit={registerAccount}
          onClick={registerAccount}
          type="submit"
        >
          Enter
        </Button>
      </Box>
    </div>
  );
}
