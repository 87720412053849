export const formStyle = {
  display: "flex",
  marginTop: "0rem",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "1rem",
};

export const textFieldStyle = {
  width: 300,
  margin: "0.5rem",
  borderRadius: "8px",
};

export const materialButtonStyle = {
  borderRadius: "8px",
  backgroundColor: "#A760FF",
};
