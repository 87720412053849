import style from "./adminstyle.module.css";
import React, { useState, useEffect } from "react";
import UserCard from "../../Components/UserCard";
import MessageBox from "../../Components/MessageBox";
import axios from "axios";
import {
  ADD_REPLY_URL,
  GET_ALL_REQUESTS_URL,
  GET_REPLY_URL,
} from "../../constansts";
import { nanoid } from "nanoid";

export default function AdminDashboard() {
  const [reqs, setReqs] = useState([]);
  const [curr, setCurr] = useState({});
  const [replies, setReplies] = useState([]);
  const [currReply, setCurrReply] = useState("");

  useEffect(() => {
    getAllRequests();
  }, []);

  const getAllRequests = async () => {
    try {
      const res = await axios.get(GET_ALL_REQUESTS_URL);
      console.log(res.data);
      setReqs(res.data);
      setCurr(
        res.data[0] || {
          requestId: "",
          userEmail: "",
          requestDesc: "",
          carNumber: "",
          carModel: "",
        }
      );
    } catch (error) {
      console.error("Error in Fetching requests from server");
      setReqs([]);
    }
  };

  const addReply = async () => {
    try {
      const data = {
        replyId: nanoid(8),
        userEmail: curr["userEmail"],
        replyMessage: `${new Date().toUTCString()} ${currReply}`,
      };
      const res = await axios.post(ADD_REPLY_URL, data);
      console.log(res.data);
      setCurrReply("");
      getAllReply();
    } catch (error) {
      console.error("Error in adding Replies");
    }
  };

  const getAllReply = async () => {
    const data = {
      userEmail: curr["userEmail"],
      someThing: "",
    };
    try {
      const res = await axios.post(GET_REPLY_URL, data);
      console.log(res.data);
      setReplies(res.data);
    } catch (error) {
      console.error("Error in getting Replies");
    }
  };

  return (
    <div className={style.adminPage}>
      <div className={style.userRequestPanel}>
        {reqs.length === 0 ? (
          <p>No Requests Yet</p>
        ) : (
          reqs.map((req) => (
            <UserCard
              key={req["requestId"]}
              user={req["userEmail"]}
              onClick={() => {
                setCurr([]);
                setCurr(req);
                getAllReply();
              }}
            />
          ))
        )}
      </div>
      <div className={style.chatPanel}>
        <div className={style.messagePanel}>
          <div className={style.requestDetails}>
            <span>
              <strong>Service Id : </strong> {curr["requestId"] || "NA"}
            </span>

            <span>
              <strong>Service User Email : </strong> {curr["userEmail"] || "NA"}
            </span>

            <span>
              <strong>Service Description : </strong>{" "}
              {curr["requestDesc"] || "NA"}
            </span>
            <span>
              <strong>Car Number : </strong> {curr["carNumber"] || "NA"}
            </span>
            <span>
              <strong>Car Model : </strong> {curr["carModel"] || "NA"}
            </span>
          </div>
          <div className={style.messageWrap}>
            {replies.map((rep) => (
              <MessageBox
                key={rep["replyId"]}
                msg={rep["replyMessage"] || "None"}
              />
            ))}
          </div>
        </div>
        <div className={style.addMessagePanel}>
          <input
            type="text"
            value={currReply}
            onChange={(e) => setCurrReply(e.target.value)}
            placeholder="Send reply message with appointment date"
            className={style.messageInput}
          />
          <button className="cta" onClick={addReply}>
            Send Message
          </button>
          &nbsp;&nbsp;
          <button
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}
