import React, { useState } from "react";
import { formStyle, textFieldStyle } from "../styles";
import { Box, TextField, Autocomplete, Button } from "@mui/material";
import axios from "axios";
import { LOGIN_URL } from "../constansts";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [userType, setUserType] = useState("Customer");

  const loginFunction = async (e) => {
    e.preventDefault();
    if (userType.toLowerCase() === "customer") {
      await loginUser();
    } else if (email === "admin" && pass === "admin") {
      localStorage.setItem("id", "admin");
      localStorage.setItem("log", "true");
      localStorage.setItem("type", "admin");
      window.location.reload();
    } else {
      Swal.fire("Login Failed");
    }
  };

  const loginUser = async () => {
    const data = {
      userEmail: email,
      userPass: pass,
    };
    try {
      const res = await axios.post(LOGIN_URL, data);
      console.log(res.data);
      if (res.data["userEmail"].length !== 0) {
        localStorage.setItem("id", email);
        localStorage.setItem("log", "true");
        localStorage.setItem("type", "customer");
        window.location.reload();
      } else {
        Swal.fire("Login Failed");
      }
    } catch (error) {
      Swal.fire("Login Failed");
    }
  };

  return (
    <div>
      <Box component="form" sx={formStyle}>
        <h2>Login</h2>
        <TextField
          id="user-email"
          onChange={(e) => setEmail(e.target.value)}
          sx={textFieldStyle}
          label="User Email"
          variant="outlined"
          type={"email"}
        />
        <TextField
          id="user-pass"
          onChange={(e) => setPass(e.target.value)}
          sx={textFieldStyle}
          label="Password"
          type={"password"}
          variant="outlined"
        />
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={["Customer", "Admin"]}
          onChange={(_, val) => setUserType(val)}
          sx={{ width: 300, margin: "0.5rem" }}
          renderInput={(params) => <TextField {...params} label="User Type" />}
        />
        <Button
          variant="contained"
          onSubmit={loginFunction}
          onClick={loginFunction}
          type="submit"
        >
          Enter
        </Button>
        <br />
        <Link to={"/register"}>Create a new account</Link>
      </Box>
    </div>
  );
}
