import React from "react";
import style from "./messageboxstyle.module.css";

export default function MessageBox({ msg }) {
  return (
    <div className={style.messageBox}>
      <span>{msg}</span>
    </div>
  );
}
