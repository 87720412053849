import React, { useEffect, useState } from "react";
import { formStyle, textFieldStyle, materialButtonStyle } from "../../styles";
import { Box, TextField, Button } from "@mui/material";
import axios from "axios";
import { ADD_REQUESTS_URL, GET_REPLY_URL } from "../../constansts";
import ReplyMessageDialog from "../../Components/ReplyMessagesDialog";
import { nanoid } from "nanoid";
import Swal from "sweetalert2";

export default function UserDashboard() {
  const [reqDesc, setReqDesc] = useState("");
  const [carModel, setCarModel] = useState("");
  const [reps, setReplies] = useState([]);
  const [carNumber, setCarNumber] = useState("");
  const [isModalOpen, setOpen] = useState(false);

  useEffect(() => {
    getReplies();
  }, []);

  const getReplies = async () => {
    const data = {
      userEmail: localStorage.getItem("id"),
      someThing: "",
    };
    try {
      const res = await axios.post(GET_REPLY_URL, data);
      console.log(res.data);
      setReplies(res.data);
    } catch (error) {
      console.error("Error in getting Replies");
    }
  };

  const addRequest = async (e) => {
    e.preventDefault();
    const data = {
      requestId: nanoid(8),
      time: parseInt(Date.now()),
      requestDesc: reqDesc,
      carModel,
      carNumber,
      userEmail: localStorage.getItem("id"),
    };
    try {
      const res = await axios.post(ADD_REQUESTS_URL, data);
      console.log(res);
      if (res.data === "RequestAdded") {
        Swal.fire("Request added successfully");
      }
    } catch (error) {
      Swal.fire("Error in adding request");
    }
  };

  return (
    <>
      <div>
        <div>
          <Box component="form" sx={formStyle}>
            <h2>Apply for Service</h2>
            <TextField
              id="req-desc"
              onChange={(e) => setReqDesc(e.target.value)}
              sx={textFieldStyle}
              label="Type Service Description Here"
              variant="outlined"
              type={"email"}
            />
            <TextField
              id="car-model"
              onChange={(e) => setCarModel(e.target.value)}
              sx={textFieldStyle}
              label="Type Your car model here"
              variant="outlined"
            />
            <TextField
              id="car-number"
              onChange={(e) => setCarNumber(e.target.value)}
              sx={textFieldStyle}
              label="Type you car number here"
              variant="outlined"
            />
            <Button
              variant="contained"
              sx={materialButtonStyle}
              onSubmit={addRequest}
              onClick={addRequest}
              type="submit"
            >
              Submit Service Request
            </Button>
            <br />
            <Button
              variant="contained"
              sx={materialButtonStyle}
              onClick={() => setOpen(true)}
            >
              View Your Replies
            </Button>
          </Box>
        </div>
      </div>
      <ReplyMessageDialog
        replies={reps}
        onOkayClicked={() => setOpen(false)}
        isOpen={isModalOpen}
      />
    </>
  );
}
