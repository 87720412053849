import { MdAddCircle } from "react-icons/md";
import { motion } from "framer-motion";
import { Button } from "@mui/material";
import "./dialogstyle.css";

const animVariants = {
  opened: {
    opacity: 1,
    scale: 1,
    top: 70,
  },
  closed: {
    opacity: 0,
    scale: 0,
    top: 0,
  },
};

const ReplyMessageDialog = ({ isOpen, onOkayClicked, replies }) => {
  return (
    <>
      <div
        className="task-modal-wrapper"
        style={{ visibility: isOpen ? "visible" : "hidden" }}
      >
        <motion.div
          initial={{ scale: 0, top: 0, opacity: 0 }}
          variants={animVariants}
          animate={isOpen ? "opened" : "closed"}
          className={`add-project-modal center-add-task`}
        >
          <div>
            <div className="messageBox">
              {replies.length !== 0 ? (
                replies
                  .slice(0)
                  .reverse()
                  .map((reply) => <p>{reply["replyMessage"]}</p>)
              ) : (
                <p>No Replies for now</p>
              )}
            </div>
            <div className="add-task-content">
              <Button onClick={onOkayClicked}>
                <MdAddCircle size={22} /> Okay
              </Button>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default ReplyMessageDialog;
